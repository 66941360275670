import {
    createStore as rawCreateStore,
    compose,
    applyMiddleware,
    Reducer,
    Store,
} from 'redux';
import { all } from 'redux-saga/effects';
import { routerMiddleware } from 'connected-react-router';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import createSagaMiddleware, { Saga, Task } from 'redux-saga';
import { saga as globalNotificationsSaga } from 'components/globalNotification';
import { history } from 'global/history';
import { createRootReducer, PageReducerMap } from './rootReducer';
import { validateCsvApi } from './api';

function* saga() {
    yield all([...globalNotificationsSaga]);
}

const devtoolCompose =
    (typeof window === 'object'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    || compose;

export type EnhancedStore = Store & {
    pageReducers: PageReducerMap;
    run: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task;
    injectReducer: <K extends keyof PageReducerMap>(
        name: K,
        reducer: Reducer
    ) => void;
};

export const createStore = (): EnhancedStore => {
    const sagaMiddleware = createSagaMiddleware();

    const store = rawCreateStore(
        createRootReducer(history, {}),
        {},
        devtoolCompose(
            applyMiddleware(
                ...getDefaultMiddleware(),
                routerMiddleware(history),
                sagaMiddleware,
                validateCsvApi.middleware,
            ),
        ),
    );

    sagaMiddleware.run(saga);

    const pageReducers: PageReducerMap = {};

    return {
        ...store,
        pageReducers,
        run: sagaMiddleware.run,
        injectReducer: <K extends keyof PageReducerMap>(
            name: K,
            reducer: PageReducerMap[K],
        ): void => {
            if (!{}.hasOwnProperty.call(pageReducers, name)) {
                pageReducers[name] = reducer;
                store.replaceReducer(createRootReducer(history, pageReducers));
            }
        },
    };
};
