import styled from 'styled-components';

export const NotificationWrapper = styled.div(
    ({ theme }) => `
        overflow-wrap: anywhere;
        margin: ${theme.spacing.s7} ${theme.spacing.s4} ${theme.spacing.s1};
    `,
);

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Wrapper = styled.div`
    position: fixed;
    margin: ${({ theme }) => theme.spacing.s2};
    z-index: 100; // The stack order of notifications is highest
`;
