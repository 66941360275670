import React, { FunctionComponent } from 'react';
import {
    createGlobalStyle,
    DefaultTheme,
    ThemeProvider,
} from 'styled-components';
import { themes } from '@bluebitellc/ui';

const GlobalStyles = createGlobalStyle`
    html {
        height: 100%;
    }

    body {
        position: relative;
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: hidden;
        font-family: ${({ theme }) => theme.primaryFont};
        color: ${({ theme }) => theme.neutralColor.dark};
        background-color: #f5f5f5;
    }

    #app {
        display: flex;
        flex-flow: column;
        height: 100%;
    }
`;

const defaultTheme = {
    ...themes.defaultTheme,
    pageMaxWidth: '1500px',
};

const StyleRoot: FunctionComponent<{ theme?: DefaultTheme }> = ({
    children,
    theme = defaultTheme,
}) => (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
    </ThemeProvider>
);

export default StyleRoot;
