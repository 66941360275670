import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const HeaderContainer = styled.header(
    ({ theme }) => `
        display: flex;
        color: ${theme.disabledColor.light};
        min-height: ${theme.spacing.s11};
        background-color: ${theme.neutralColor.extraDark};
        justify-content: space-between;
    `,
);

export const HeaderLogo = styled.div`
    display: flex;
    background: ${({ theme }) => theme.primaryColor.normal};
    width: 167px;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const HeaderLogoImage = styled.img(
    ({ theme }) => `
        height: 20.4px;
        width: ${theme.spacing.s13};
    `,
);

export const HeaderItems = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const HeaderDivider = styled.div`
    align-self: stretch;
    background-color: #393e48;
    width: 1px;
`;

export const HeaderLink = styled(NavLink)(
    ({ theme }) => `
        display: flex;
        font-size: ${theme.fontSize.fs5};
        height: 100%;
        color: #c1c2c2;
        text-decoration: none;
        align-items: center;
        margin: 0px 30px 0px 30px;

        &.active {
            color: ${theme.neutralColor.superExtraLight};
        }

        &:hover {
            color: ${theme.neutralColor.superExtraLight};
            text-shadow: 0px 0px 1px #f1e6e6;
        }
    `,
);

export const HeaderNavLink = styled(HeaderLink)(
    ({ theme }) => `
        margin: 0px 0px 0px ${theme.spacing.s9};

        &.active {
            box-shadow: inset 0 -3px 0 ${theme.primaryColor.normal};
        }
    `,
);

export const HeaderContent = styled.div`
    display: flex;
    align-items: center;
`;
