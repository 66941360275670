import {
    AuthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
} from '@azure/msal-react';
import React, { ReactElement, useMemo } from 'react';

import { Header } from 'components/header';
import { GlobalNotifications } from 'components/globalNotification';
import { GlobalAlerts } from 'components/globalAlert';
import Pages, { RoleInfo, UserRole, UserRoleType } from '../../pages';

const routes = [
    {
        path: '/lineitems',
        title: 'Line Items',
        roles: null,
    },
    {
        path: '/assets',
        title: 'Assets',
        roles: [UserRole.AdopsManager],
    },
];

const App = (): ReactElement => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    let currentUserRole: UserRoleType | null = null;

    if (isAuthenticated) {
        currentUserRole = (accounts[0] as RoleInfo)?.idTokenClaims
            .extension_Role;
    }
    const headerRoutes = useMemo(
        () => routes.filter(
                (item) => !item.roles
                    || (currentUserRole && item.roles.includes(currentUserRole)),
            ),
        [currentUserRole],
    );

    return (
        <>
            <AuthenticatedTemplate>
                <Header routes={headerRoutes} />
                <GlobalAlerts />
                <GlobalNotifications />
            </AuthenticatedTemplate>

            <Pages />
        </>
    );
};

export default App;
