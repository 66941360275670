import React, { ReactElement, useEffect } from 'react';
import { Toast } from '@bluebitellc/ui';
import { useDispatch } from 'react-redux';

import { history } from 'global/history';
import {
    removeNotification,
    removeAllNotifications,
    GlobalNotification,
} from './reducer';
import { NotificationWrapper } from './styles';

export const Notification = ({
    buttons,
    id,
    logLevel,
    message,
    title,
}: GlobalNotification): ReactElement => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unListen = history.listen(() => {
            dispatch(removeAllNotifications());
        });
        return unListen;
    }, [dispatch]);

    return (
        <NotificationWrapper>
            <Toast
                buttons={buttons}
                logLevel={logLevel}
                message={message}
                onClose={() => dispatch(removeNotification({ id }))}
                title={title}
            />
        </NotificationWrapper>
    );
};
