import React, { useState, useEffect } from 'react';
import loadable, { LoadableComponent } from '@loadable/component';
import { useStore } from 'react-redux';
import { Task } from 'redux-saga';
import type { EnhancedStore } from '../global/store';
import { PageState, PageModule, PageComponentProps } from './types';

export const PageLoader = <K extends keyof PageState>(
    pageModule: PageModule<K>,
): LoadableComponent<PageComponentProps> => (
    loadable(async () => {
        const {
            pageName,
            reducer,
            saga,
            Page = () => null,
        } = await pageModule();
        const PageComponent = (props: PageComponentProps): React.ReactElement | null => {
            const [loading, setLoading] = useState(true);
            const store = (useStore() as EnhancedStore);
            useEffect(() => {
                let task: Task | null = null;
                if (reducer) {
                    store.injectReducer(pageName, reducer);
                }
                if (saga) {
                    task = store.run(saga, props);
                }
                // yield one cycle for reducer to register
                setTimeout(() => {
                    setLoading(false);
                }, 0);

                return () => {
                    if (task) {
                        task.cancel();
                    }
                };
            // If these change we should not remount the component
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
            }, []);
            if (loading) { return null; }
            return <Page {...props} />;
        };

        return PageComponent;
    })
);
