import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from './notification';
import { GlobalNotification, selectGlobalNotifications } from './reducer';
import { Wrapper, Container } from './styles';

export const GlobalNotifications = (): ReactElement => {
    const globalNotifications = useSelector(selectGlobalNotifications);

    return (
        <Container>
            <Wrapper>
                {globalNotifications.map((notification: GlobalNotification) => (
                    <Notification
                        buttons={notification.buttons}
                        dismissAfterTimeMs={notification.dismissAfterTimeMs}
                        id={notification.id}
                        key={notification.id}
                        logLevel={notification.logLevel}
                        message={notification.message}
                        title={notification.title}
                    />
                ))}
            </Wrapper>
        </Container>
    );
};
