import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from 'global/utils/accessToken';
import { RowDataType } from 'pages/lineItems/list/columnData';

export type ValidationResponse = {
    errors: [
        {
            path: string;
            message: string;
        }
    ];
    instance: {
        id: string;
        vehicle: {
            id: {
                level: string;
            };
        };
    };
    isValid: boolean;
};

type ValidationResponseBody = Array<ValidationResponse> | undefined;

export type LineItemsResponse = {
    instance: {
        id: string;
        vehicle: {
            id: {
                level: string;
            };
        };
    };
};

type LineItemsResponseBody = Array<LineItemsResponse> | undefined;

export type TrimmedResponse = Array<RowDataType> | undefined;

const trimmedValidationResponse = (response: ValidationResponseBody) => {
    const trimmedResponse = response
        ?.filter((rowItem) => rowItem.isValid === false)
        .map((rowItem) => rowItem.errors.map((e) => ({
                internalId: rowItem.instance.id ?? 'NULL',
                gpsId: rowItem.instance.vehicle.id.level
                    ? 'NULL'
                    : rowItem.instance.vehicle.id,
                error:
                    e.message === 'Got unexpected null value'
                        ? `${e.path}: ${e.message}`
                        : e.message ?? 'Invalid value for line item',
            })));
    if (trimmedResponse?.length === 0) return undefined;
    return trimmedResponse?.flat(1);
};

const trimmedLineItemsResponse = (response: LineItemsResponseBody) => {
    const trimmedResponse = response?.map((rowItem) => ({
        internalId: rowItem.instance.id,
        gpsId: rowItem.instance.vehicle.id,
        fullConfig: JSON.stringify(rowItem),
    }));
    if (trimmedResponse?.length === 0) return undefined;
    return trimmedResponse;
};

export const validateCsvApi = createApi({
    reducerPath: 'validateCsvApi',
    baseQuery: fetchBaseQuery({
        baseUrl: __BASE_API__,
        prepareHeaders: async (headers) => {
            const accessToken = await getAccessToken();
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        validateCSV: builder.mutation<
            ValidationResponseBody | TrimmedResponse,
            FormData
        >({
            query: (formdata: FormData) => ({
                url: '/validation-page',
                method: 'POST',
                body: formdata,
            }),
            transformResponse: (
                response: ValidationResponseBody,
            ): TrimmedResponse => trimmedValidationResponse(response),
        }),
        lineItems: builder.mutation<
            LineItemsResponseBody | TrimmedResponse,
            FormData
        >({
            query: (formdata: FormData) => ({
                url: '/persist-config',
                method: 'POST',
                body: formdata,
            }),
            transformResponse: (
                response: LineItemsResponseBody,
            ): TrimmedResponse => trimmedLineItemsResponse(response),
        }),
    }),
});

export const { useValidateCSVMutation, useLineItemsMutation } = validateCsvApi;
