import styled from 'styled-components';

export const AlertWrapper = styled.div`
    overflow-wrap: anywhere;
    margin: ${({ theme }) => theme.spacing.s1};
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    position: fixed;
    margin-top: ${({ theme }) => theme.spacing.s1};
    width: 100%;
    /* The stack order of alerts are greater
     than other page components and less than the global notifications */
    z-index: 1;
`;
