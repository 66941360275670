import { createAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { LogLevelType } from '@bluebitellc/ui';
import { delay, put, takeEvery } from 'redux-saga/effects';

import type { RootState } from 'global/rootReducer';

export type GlobalNotification = {
    buttons?: Array<{
        children: string;
        key: string;
        onClick: () => void;
    }>;
    dismissAfterTimeMs?: number;
    id: string;
    logLevel: LogLevelType;
    message: string;
    title: string;
};

type AddNotificationPayload = GlobalNotification;

export const addNotification = createAction<AddNotificationPayload>(
    'ADD_GLOBAL_NOTIFICATION',
);

type RemoveNotificationPayload = {
    id: string;
};

export const removeNotification = createAction<RemoveNotificationPayload>(
    'REMOVE_GLOBAL_NOTIFICATION',
);

export const removeAllNotifications = createAction(
    'REMOVE_ALL_GLOBAL_NOTIFICATIONS',
);
type ShowNotificationPayload = GlobalNotification;

export const showNotification =
    createAction<ShowNotificationPayload>('SHOW_NOTIFICATION');

type RemoveNotificationAfterTimeoutPayload = {
    id: string;
    timeout: number;
};

export const removeNotificationAfterTimeout =
    createAction<RemoveNotificationAfterTimeoutPayload>(
        'REMOVE_NOTIFICATION_AFTER_TIMEOUT',
    );

const initialState = {
    globalNotifications: [] as Array<GlobalNotification>,
};

export const reducer = (
    state = initialState,
    action: AnyAction,
): typeof initialState => {
    if (addNotification.match(action)) {
        return {
            ...state,
            globalNotifications: [...state.globalNotifications, action.payload],
        };
    }
    if (removeNotification.match(action)) {
        return {
            ...state,
            globalNotifications: state.globalNotifications.filter(
                (notification) => notification.id !== action.payload.id,
            ),
        };
    }
    if (removeAllNotifications.match(action)) {
        return {
            ...state,
            globalNotifications: [],
        };
    }
    return state;
};

export default reducer;

export type LocalState = ReturnType<typeof reducer>;

const selectLocalState = (state: RootState) => state.globalNotifications;
export const selectGlobalNotifications = (
    state: RootState,
): Array<GlobalNotification> => selectLocalState(state).globalNotifications;

function* showNotificationSaga({
    payload,
}: ReturnType<typeof showNotification>) {
    const notification = payload;
    yield put(addNotification(notification));

    if (
        notification.dismissAfterTimeMs
        && notification.dismissAfterTimeMs > 0
    ) {
        yield put(
            removeNotificationAfterTimeout({
                id: notification.id,
                timeout: notification.dismissAfterTimeMs,
            }),
        );
    }
}

function* removeNotificationAfterTimeoutSaga({
    payload,
}: ReturnType<typeof removeNotificationAfterTimeout>) {
    const { id, timeout } = payload;
    yield delay(timeout);
    yield put(removeNotification({ id }));
}

export const saga = [
    takeEvery(showNotification.toString(), showNotificationSaga),
    takeEvery(
        removeNotificationAfterTimeout.toString(),
        removeNotificationAfterTimeoutSaga,
    ),
];
