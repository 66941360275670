import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';

import { history } from 'global/history';
import App from './global/app';
import { createStore } from './global/store';
import StyleRoot from './global/styleRoot';
import { msalInstance } from './global/utils/accessToken';

const store = createStore();

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <StyleRoot>
                    <App />
                </StyleRoot>
            </ConnectedRouter>
        </Provider>
    </MsalProvider>,
    document.getElementById('app'),
);
