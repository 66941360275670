import React, { ReactElement, useEffect } from 'react';
import { Ribbon } from '@bluebitellc/ui';
import { useDispatch } from 'react-redux';

import { history } from 'global/history';
import { removeAlert, removeAllAlerts, GlobalAlert } from './reducer';
import { AlertWrapper } from './styles';

export const Alert = ({
    id,
    isClosable = true,
    link,
    logLevel,
    message,
}: GlobalAlert): ReactElement => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unListen = history.listen(() => {
            dispatch(removeAllAlerts());
        });
        return unListen;
    }, [dispatch]);

    return (
        <AlertWrapper>
            <Ribbon
                isCloseable={isClosable}
                key={id}
                logLevel={logLevel}
                maxWidth="100%"
                onClose={() => dispatch(removeAlert({ id }))}
            >
                {message}
                {' '}
                <a href={link}>{link}</a>
            </Ribbon>
        </AlertWrapper>
    );
};
