import React, { ReactElement } from 'react';
import logo from 'assets/images/logo-blue-bite-1/logo-bluebite@3x.png';
import { LOGOUT_PATH } from 'pages/paths';
import { UserRoleType } from 'pages';
import {
    HeaderContainer,
    HeaderLogo,
    HeaderLogoImage,
    HeaderItems,
    HeaderNavLink,
    HeaderLink,
    HeaderContent,
    HeaderDivider,
} from './styles';

type HeaderProps = {
    routes: {
        path: string;
        title: string;
        roles: Array<UserRoleType> | null;
    }[];
};

export const Header = ({ routes }: HeaderProps): ReactElement => (
    <HeaderContainer>
        <HeaderContent>
            <HeaderLogo>
                <HeaderLogoImage src={logo} />
            </HeaderLogo>
            <HeaderItems>
                {routes
                    && routes.map((item) => (
                        <HeaderNavLink key={item.path} to={item.path}>
                            {item.title}
                        </HeaderNavLink>
                    ))}
            </HeaderItems>
        </HeaderContent>
        <HeaderContent>
            <HeaderDivider />
            <HeaderLink to={LOGOUT_PATH}>Sign Out</HeaderLink>
        </HeaderContent>
    </HeaderContainer>
);
