import { Configuration, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: __CLIENT_ID__,
        // eslint-disable-next-line max-len
        authority: `https://${__TENANT_NAME__}.b2clogin.com/${__TENANT_NAME__}.onmicrosoft.com/${__SIGN_IN_FLOW__}`,
        knownAuthorities: [
            `https://${__TENANT_NAME__}.b2clogin.com/${__TENANT_NAME__}.onmicrosoft.com`,
        ],
        redirectUri: `${__REDIRECT_URI__}`,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
    },
};

export const loginRequest: RedirectRequest = {
    scopes: ['openid', 'profile', 'offline_access', __CLIENT_ID__],
};
