import { createAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { LogLevelType } from '@bluebitellc/ui';

import type { RootState } from 'global/rootReducer';

export type GlobalAlert = {
    id: string;
    isClosable: boolean;
    link: string;
    logLevel: LogLevelType;
    message: string;
};

type AddAlertPayload = GlobalAlert;

export const addAlert = createAction<AddAlertPayload>('ADD_GLOBAL_ALERT');

type RemoveAlertPayload = {
    id: string;
};

export const removeAlert = createAction<RemoveAlertPayload>(
    'REMOVE_GLOBAL_ALERT',
);

export const removeAllAlerts = createAction('REMOVE_ALL_GLOBAL_ALERTS');

const initialState = {
    globalAlerts: [] as Array<GlobalAlert>,
};

export const reducer = (
    state = initialState,
    action: AnyAction,
): typeof initialState => {
    if (addAlert.match(action)) {
        return {
            ...state,
            globalAlerts: [...state.globalAlerts, action.payload],
        };
    }
    if (removeAlert.match(action)) {
        return {
            ...state,
            globalAlerts: state.globalAlerts.filter(
                (alert) => alert.id !== action.payload.id,
            ),
        };
    }
    if (removeAllAlerts.match(action)) {
        return {
            ...state,
            globalAlerts: [],
        };
    }
    return state;
};

export default reducer;

export type LocalState = ReturnType<typeof reducer>;

const selectLocalState = (state: RootState) => state.globalAlerts;

export const selectGlobalAlerts =
    (state: RootState): Array<GlobalAlert> => selectLocalState(state).globalAlerts;
