import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';

import {
    reducer as globalNotificationReducer,
    LocalState as GlobalNotificationState,
} from 'components/globalNotification';
import {
    reducer as globalAlertReducer,
    LocalState as GlobalAlertState,
} from 'components/globalAlert';
import { validateCsvApi } from 'global/api';
import type { PageState } from '../pages';

export type PageReducerMap = { [K in keyof PageState]?: Reducer<PageState[K]> };

export type RootState = {
    globalAlerts: GlobalAlertState;
    globalNotifications: GlobalNotificationState;
    pages: PageState;
    router: RouterState;
};

export const createRootReducer = (
    history: History,
    pageReducers: PageReducerMap,
): Reducer => combineReducers({
        globalAlerts: globalAlertReducer,
        globalNotifications: globalNotificationReducer,
        pages:
            Object.keys(pageReducers).length > 0
                ? combineReducers(pageReducers)
                : () => ({}),
        router: connectRouter(history),
        [validateCsvApi.reducerPath]: validateCsvApi.reducer,
    });
