import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Alert } from './alert';
import { GlobalAlert, selectGlobalAlerts } from './reducer';
import { Wrapper, Container } from './styles';

export const GlobalAlerts = (): ReactElement => {
    const globalAlerts = useSelector(selectGlobalAlerts);

    return (
        <Container>
            <Wrapper>
                {globalAlerts.map((alert: GlobalAlert) => (
                    <Alert
                        id={alert.id}
                        isClosable={alert.isClosable}
                        key={alert.id}
                        link={alert.link}
                        logLevel={alert.logLevel}
                        message={alert.message}
                    />
                ))}
            </Wrapper>
        </Container>
    );
};
