import React from 'react';
import { Switch } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';

import { lineItemsImport, lineItemsList } from './lineItems';
import { login, logout } from './authentication';
import { assetList } from './assets';
import {
    ASSETS_PATH,
    LANDING_PATH,
    LINEITEMS_IMPORT_PATH,
    LINEITEMS_PATH,
    LOGIN_PATH,
    LOGOUT_PATH,
} from './paths';

export * from './types';

export enum UserRole {
    AdopsManager = 'Adops Manager',
    SuperAdmin = 'Super Admin',
}

export type UserRoleType = typeof UserRole[keyof typeof UserRole];

export interface AuthRoles {
    roles?: Array<UserRoleType>;
}
export interface RoleInfo extends AccountInfo {
    idTokenClaims: {
        // eslint-disable-next-line camelcase
        extension_Role: UserRoleType; // Custom user attributes have a prefix 'extension_'
    };
}

const RouteAuthenticated = ({
    component: Component,
    path,
    exact,
    roles,
}: RouteProps & AuthRoles) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    if (!isAuthenticated) {
        return (
            <Route exact={exact} path={path}>
                <Redirect to={{ pathname: LOGIN_PATH }} />
            </Route>
        );
    }
    const currentUserRole = (accounts[0] as RoleInfo)?.idTokenClaims
        .extension_Role;

    if (roles && !roles.includes(currentUserRole)) {
        // role not authorized so redirect to landing page
        return (
            <Route exact={exact} path={path}>
                <Redirect to={{ pathname: LANDING_PATH }} />
            </Route>
        );
    }
    return <Route component={Component} exact={exact} path={path} />;
};

const RouteUnauthenticated = ({
    component: Component,
    path,
    exact,
}: RouteProps) => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return (
            <Route exact={exact} path={path}>
                <Redirect to={{ pathname: LANDING_PATH }} />
            </Route>
        );
    }
    return <Route component={Component} exact={exact} path={path} />;
};

const Pages = (): React.ReactElement => (
    <Switch>
        <RouteAuthenticated
            path={LINEITEMS_PATH}
            component={lineItemsList}
            exact
        />
        <RouteAuthenticated
            path={LINEITEMS_IMPORT_PATH}
            component={lineItemsImport}
            exact
        />
        <RouteUnauthenticated path={LOGIN_PATH} component={login} exact />
        <RouteAuthenticated path={LOGOUT_PATH} component={logout} exact />
        <RouteAuthenticated
            path={ASSETS_PATH}
            component={assetList}
            exact
            roles={[UserRole.AdopsManager]}
        />
        <Route exact path="/">
            <Redirect to={{ pathname: LOGIN_PATH }} />
        </Route>
        <Route path="*">
            <Redirect to={{ pathname: LOGIN_PATH }} />
        </Route>
    </Switch>
);

export default Pages;
