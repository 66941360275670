import {
    InteractionRequiredAuthError,
    PublicClientApplication,
} from '@azure/msal-browser';
import { loginRequest, msalConfig } from 'global/authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAccessToken = async (): Promise<string | undefined> => {
    const accounts = await msalInstance.getAllAccounts();
    const request = {
        ...loginRequest,
        account: accounts[0],
    };
    /* Gets accessToken from cache.
    When no valid token is in the cache, it attempts to use its refresh token to get the token. */
    const response = await msalInstance
        .acquireTokenSilent(request)
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                return msalInstance.acquireTokenRedirect(loginRequest);
            }
        });
    return response?.accessToken;
};
